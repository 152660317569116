<template>
  <Layout>
    <div class="container-fluid pt-5" style="margin-bottom: 120px">
      <companyName :name="company.company.name"/>
      <btn-back/>
      <div class="big-title mb-2">
        Cobra and Health Expenses
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <BootStrapAlert/>
        </div>
        <div class="col-md-12 col-lg-12">
          <div class="title mt-2 mb-1">
            Qualified Health Plan Expenses
          </div>
          <p class="description">
            Health insurance premiums paid by the employer for employees who are
            not enrolled in COBRA coverage, such as those who are still employed by the employer but are not eligible for group health plan coverage.
          </p>
          <p class="description">
            Health Flexible Spending Account (FSA) contributions made by the employer on behalf of its employees.
          </p>
          <p class="description">
            Health Savings Account (HSA) contributions made by the employer on behalf of its employees who are covered
            under a high-deductible health plan.
          </p>
          <p class="description">
            Short-term and long-term disability insurance premiums paid by the employer for its employees.
          </p>
          <p class="description">
            Vision and dental insurance premiums paid by the employer for its employees
          </p>


          <div class="row gross">
            <div class="col-md-12">
              <div class="gross-head mb-3">
                2020
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 1 : JAN - MAR</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2020.q1"
                    min="0" name="Q1-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-1 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 2 : APR - JUN</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2020.q2"
                    min="0" name="Q2-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-2 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 3 : JUL - SEP</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2020.q3"
                    min="0" name="Q3-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-3 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 4 : OCT - DEC</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2020.q4"
                    min="0" name="Q4-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-4 form-control">
              </div>
            </div>

          </div>

          <div class="row gross">
            <div class="col-md-12">
              <div class="gross-head mb-3">
                2021
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 1 : JAN - MAR</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2021.q1"
                    min="0" name="Q1-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-1 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 2 : APR - JUN</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2021.q2"
                    min="0" name="Q2-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-2 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 3 : JUL - SEP</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2021.q3"
                    min="0" name="Q3-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-3 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 4 : OCT - DEC</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.healthExpenses._2021.q4"
                    min="0" name="Q4-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-4 form-control">
              </div>
            </div>
            <div class="col-md-12 mt-2">
              <hr>
            </div>
          </div>

          <div class="title mb-1">
            COBRA Expenses
          </div>
          <p class="description">
            If your company was required to pay COBRA, please list the amount per quarter
          </p>
          <div class="row gross">
            <div class="col-md-12">
              <div class="gross-head mb-3">
                2020
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 1 : JAN - MAR</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2020.q1"
                    min="0" name="Q1-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-1 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 2 : APR - JUN</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2020.q2"
                    min="0" name="Q2-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-2 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 3 : JUL - SEP</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2020.q3"
                    min="0" name="Q3-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-3 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 4 : OCT - DEC</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2020.q4"
                    min="0" name="Q4-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-4 form-control">
              </div>
            </div>

          </div>

          <div class="row gross">
            <div class="col-md-12">
              <div class="gross-head mb-3">
                2021
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 1 : JAN - MAR</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2021.q1"
                    min="0" name="Q1-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-1 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 2 : APR - JUN</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2021.q2"
                    min="0" name="Q2-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-2 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 3 : JUL - SEP</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2021.q3"
                    min="0" name="Q3-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-3 form-control">
              </div>
            </div>
            <div class="col-lg-3">
              <div class="gross-sub-head">Quarter 4 : OCT - DEC</div>
              <div class="form-group mt-1">
                <input
                    v-model="user.cobraExpenses._2021.q4"
                    min="0" name="Q4-2019" type="number" placeholder="$0.00"
                       class="gross-input-element row-4 form-control">
              </div>
            </div>
            <div class="col-md-12 mt-2">
              <hr>
            </div>
          </div>
          <div>
            <button type="button" class="btn btn-save px-5" @click="onBtnClickedSave">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import {inject} from "vue"
import Layout from "@/layouts/main";
import BootStrapAlert from "@/components/utils/Alert";
import btnBack from "@/components/btn-back/btnBack";
import companyName from "@/components/company-name/company-name";
import {companyStore} from "@/store/company";
import {userStore} from "../../../../store/user";
const company = companyStore();
const user = userStore()
const changeStatus = inject('changeStatus')
function onBtnClickedSave(){
  user.saveCobraHealth(changeStatus)
}


</script>

<style scoped lang="scss" src="./health-expenses.scss"></style>